$dark-primary-text: #000;
$light-primary-text: #000;

:root {
  --ushg-dark-red: #b30b00;
  --ushg-red: #db0037;
  --ushg-blue-lighter: rgba(0, 34, 80, 0.7);
  --ushg-dark-blue: #002250;
  --ushg-dark-grey-dark: #80949d;
  --ushg-dark-grey: #80949d;
  --ushg-grey: #aec0cb;
  --ushg-grey-darkest: #1f2432;
  --ushg-grey-light: #d0dce2;
  --ushg-grey-lighter: #f2f5f7;
  --ushg-grey-lightest: #f8fafb;
  --ushg-royal-blue: rgba(8, 94, 210, 0.9);
  --ushg-grey-darker: #424b5a;
  --ushg-text-green: #8bbe8b;
  --ushg-text-red: #d96767;
}

/* You can add global styles to this file, and also import other style files */
// Global vendors
@import 'bootstrap/dist/css/bootstrap.css';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@import 'ngx-toastr/toastr-bs5-alert';

// // => Material
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

:root {
  --ushg-dark-red: #b30b00;
  --ushg-red: #db0037;
  --ushg-blue-lighter: rgba(0, 34, 80, 0.7);
  --ushg-dark-blue: #002250;
  --ushg-dark-grey-dark: #80949d;
  --ushg-dark-grey: #80949d;
  --ushg-grey: #aec0cb;
  --ushg-grey-darkest: #1f2432;
  --ushg-grey-light: #d0dce2;
  --ushg-grey-lighter: #f2f5f7;
  --ushg-grey-lightest: #f8fafb;
  --ushg-royal-blue: rgba(8, 94, 210, 0.9);
  --ushg-grey-darker: #424b5a;
  --ushg-text-green: #8bbe8b;
  --ushg-text-red: #d96767;
}

// button:focus {
//   box-shadow: rgb(208 220 225) 0px 0px 0px 2px !important;
// }
// p:focus {
//   box-shadow: rgb(208 220 225) 0px 0px 0px 2px !important;
// }
// input:focus {
//   box-shadow: rgb(208 220 225) 0px 0px 0px 2px !important;
// }
// span:focus {
//   box-shadow: rgb(208 220 225) 0px 0px 0px 2px !important;
// }
a:focus {
  // box-shadow: rgb(208 220 225) 0px 0px 0px 2px !important;
}

html,
body,
app-layout,
.screen {
  height: 100vh !important;
  width: 100vw !important;
  overflow: hidden;
}

.flex-row-full {
  display: flex;
  flex-direction: row;
  width: 100vw;
}

.flex-col-w {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.flex-row-w {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.flex-col-full {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100%;
}

.content-l {
  border-left: 1px solid #d0dce2;
}
.content-r {
  border-right: 1px solid #d0dce2;
}
.content-b {
  border-bottom: 1px solid #d0dce2;
}
.content-t {
  border-top: 1px solid #d0dce2;
}

@font-face {
  font-family: SansExtraBold;
  src: url(assets/fonts/OpenSans-ExtraBold.ttf) format('truetype');
}

.ExtraSansBold {
  font-family: SansExtraBold;
}

.btnBackground {
  background-color: var(--ushg-blue-lighter);
  border-color: transparent;
}

// hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
  display: none !important;
}

.angular-bootstrap-table {
  overflow-x: auto;
}
.angular-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead th.sortable-active {
    color: var(--ushg-blue-lighter);
  }
}

.cursor-default {
  cursor: default !important;
}

.progress-modal {
  height: 3px;
  width: 100%;
}

@media screen and (max-width: 450px) {
  .btn-block-xs-only {
    display: block;
    width: 100%;
  }
}

.content {
  padding: 0;
}

.remove-border-bottom .mat-tab-header {
  border-bottom: none !important;
}

.toast-success .toast-message::after {
  font-family: 'Material Icons';
  content: 'close';
  color: rgba(31, 36, 50, 0.5);
  font-size: 22px;
  position: absolute;
  right: 15px;
  bottom: 17px;
}

.toast-success .toast-title,
.toast-error .toast-title {
  text-align: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal !important;
}

.toast-success .toast-message,
.toast-error .toast-message {
  float: right;
  display: inline;
  // padding-right: 3em;

  color: #002250 !important;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  text-decoration-line: underline;
}

.toast-success,
.toast-error {
  background-image: none;
}

.toast-success {
  background-image: none;
  background-color: #bfc8d3;
}

.toast-container .ngx-toastr {
  padding: 0.75rem 1.25rem !important;
}

.myPanelClass {
  margin-left: -30px !important;
}

.modal-content {
  border-radius: 0;
}

.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 100vw;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.left .modal-body,
.modal.right .modal-body {
  padding: 15px 15px 80px;
}

/*Left*/
.modal.left.fade .modal-dialog {
  left: -100vw;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.show .modal-dialog {
  left: 0;
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: -100vw;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
  right: 0;
}

body {
  background-color: #fff;
}

body.message-center {
  background-color: #f2f5f7;
}

.flex-1 {
  flex: 1;
}
.usgh-minH {
  min-height: calc(100vh - 285px);
  @media only screen and (max-width: 767px) {
    min-height: calc(100vh - 260px);
  }
}

// img-icons
.pdf-icon {
  color: #cc0202;
}

.ppt-icon {
  color: #de3e03;
}

.doc-icon {
  color: #009dd8;
}

.excel-icon {
  color: #009e3b;
}

.img-icon {
  color: #1f2432;
}

.txt-icon {
  color: #1f2432;
}

.zip-icon {
  color: #1f2432;
}

.ql-container {
  height: auto;
}
.ql-editor {
  height: auto;
  word-break: break-word;
  white-space: normal;
}
.ql-toolbar.ql-snow {
  border: 0;
  border-bottom: 1px solid rgba(208, 220, 226, 0.6);
}

.ql-container.ql-snow {
  border: 0;
}
.replyMsg_sender span.ql-editor {
  padding: 0;
}
.replyMsg_textarea .ql-editor,
.createMsg_textarea_inner .ql-editor {
  min-height: 150px;
}

@media only screen and (max-width: 530px) {
  .ql-toolbar.ql-snow .ql-formats {
    margin-right: 6px;
  }

  .ql-snow.ql-toolbar button,
  .ql-snow .ql-toolbar button {
    height: 20px;
    width: 19px;
  }
}
.viewMsg_body {
  .ql-editor {
    padding: 0 !important;
  }
  a {
    cursor: pointer;
  }
}
@media print {
  .viewMsg {
    display: block;
    border-bottom: 1px solid #d0dce2 !important;
    padding: 0 !important;
  }
  .inbox-wrapper {
    display: none !important;
  }
  .viewMsg_print,
  .viewMsg_header,
  .viewMsg_btns,
  .inbox-headerUs,
  app-header-mobile,
  app-header {
    display: none !important;
  }
  .viewMsg_scroll {
    height: 100%;
  }
}

app-create-password {
  width: 100%;
}
.filters-wrap {
  .mat-form-field-infix {
    display: flex;
    width: auto !important;
  }
  .material-icons {
    margin: 0 !important;
  }
  .mat-select {
    width: auto !important;
  }
  .mat-select-empty {
    width: 0 !important;
  }
  .mat-select-value {
    max-width: 90px !important;
  }
}

.inbox-headerUs {
  .search-bar {
    border-radius: 8px !important;
  }
  .search-suggestions {
    position: absolute;
    width: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 4px 4px;
    background: #fff;
    z-index: 9;
    max-height: 320px;
    overflow-y: auto;
    padding: 10px 0;
    .card {
      margin: 0 20px;
      background: transparent;
      border: 0;
      padding: 6px 0;
      border-bottom: 1px solid #d0dce0;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
    }
  }
  .search-bar.active {
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px 4px 0px 0px !important;
    border: 1px solid rgba(208, 220, 226, 0.6);
  }
}
.search-suggestions {
  .card {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    .suggestion-row {
      .suggestion-text {
        font-size: 14px;
        line-height: 30px;
        cursor: pointer;
        p {
          margin: 0 !important;
        }

        b {
          font-weight: 700;
        }
      }
    }
  }

  .suggestion-title {
    text-align: left;
    color: #828282;
    font-size: 12px !important;
    padding: 4px 0 0;
    padding-left: 12px;
    margin: 0;
  }
}
.no-records-found {
  text-align: center;
}
span.attach-info {
  position: relative;
  left: 7px;
  top: 1px;
  cursor: pointer;
}
.attachment-info {
  position: absolute;
  bottom: 30px;
  width: 270px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 4px;
  padding: 18px;
  display: none;
  left: -120px;
  h3 {
    font-size: 14px;
    color: #000000;
    font-weight: 600;
    letter-spacing: 0.4px;
  }
  p {
    padding: 0 !important;
    font-size: 12px !important;
    line-height: 21px !important;
    color: #1f2432 !important;
  }
}
.attachment-info:before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #fff;
  bottom: -8px;
  z-index: 9;
  left: 47%;
  transform: translateX(-50%);
}
span.attach-info:hover .attachment-info {
  display: block;
}

// remove-inline-css
.widthone {
  width: 1000px;
}
.padd-left {
  padding-left: 0 !important;
  padding-right: 0;
}
.width100 {
  width: 100%;
}
.max175 {
  max-width: 175px;
}

.suggestion-text {
  p:first-child {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  p {
    display: none;
  }
  br {
    display: none;
  }
}

/* Chrome, Safari, Edge, Opera */
.typenumber input::-webkit-outer-spin-button,
.typenumber input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.typenumber input[type='number'] {
  -moz-appearance: textfield;
}

input::-ms-reveal,
input::-ms-clear {
  display: none !important;
}

.help-overlay {
  border-radius: 5px;
  padding: 8px;
  background-color: #fff !important;
  box-shadow: 0 0 5px #ccc;
  * {
    background-color: transparent !important;
    color: #000 !important;
  }
}

.sort-trigger {
  cursor: pointer;
}
.sort-menu {
  .mat-mdc-menu-content {
    padding: 0;
  }
  button {
    font-family: Roboto, Helvetica Neue, sans-serif;
    border: none;
    background: none;
    font-size: 12px !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    padding: 8px 16px;
    min-height: 40px;
    outline: none;
    * {
      border: none;
      outline: none;
      margin: 0;
      padding: 0;
      font-family: Roboto, Helvetica Neue, sans-serif;
      font-size: 12px !important;
    }
    &:hover {
      text-decoration: underline;
    }
    &.selected {
      background: rgba(0, 0, 0, 0.12);
    }
  }
}

.compose-btn {
  display: none !important;
}

.modal-backdrop {
  z-index: 1000 !important;
}

.mat-mdc-menu-item-text {
  font-family: 'Open Sans' !important;
  color: #002250 !important;
  font-size: 14px !important;
  border: none !important;
  text-decoration: none !important;

  &:hover,
  &:focus {
    background: none !important;
    text-decoration: none !important;
    font-weight: 600 !important;
    border: none !important;
  }
}

.mat-mdc-menu-content {
  padding: 0 24px 0 16px !important;
}

.mat-mdc-menu-item:not([disabled]):hover {
  background: none !important;
  text-decoration: none !important;
  border: none !important;
}

a.with-over,
button.with-over {
  min-height: 42px !important;
  position: relative;
  .over,
  .down {
    display: none;
    position: absolute;
    right: -24px;
  }
  &:hover {
    .over,
    .down {
      display: inline;
      right: -20px;
    }
  }
  &.inner {
    margin-left: 20px;
    width: calc(100% - 20px);
    .link {
      color: black !important;
      text-decoration: none;
      &:hover {
        color: #002250 !important;
      }
    }
  }
}

.mat-badge-warn {
  --mat-badge-background-color: var(--ushg-dark-red);
  --mat-badge-text-color: white;
}
